<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async getReports (rowsPerPage) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    const url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports?count=' + rowsPerPage
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async refreshReports (rowsPerPage, searchURL, currentPage) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    let url
    if (searchURL !== null || searchURL === '') {
      url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports' + searchURL
    } else {
      url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports?count=' + rowsPerPage
    }
    if (currentPage) {
      url = url + currentPage
    }
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getPreviousPage (rowsPerPage, searchURL, prevCursor) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    let url
    if (searchURL !== null || searchURL === '') {
      url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports' + searchURL + '&previous=' + prevCursor
    } else if (searchURL === null) {
      url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports?count=' + rowsPerPage + '&previous=' + prevCursor
    }
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getNextPage (rowsPerPage, searchURL, nextCursor) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    let url
    if (searchURL !== null || searchURL === '') {
      url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports' + searchURL + '&next=' + nextCursor
    } else if (searchURL === null) {
      url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports?count=' + rowsPerPage + '&next=' + nextCursor
    }
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async filterReports (rowsPerPage, searchURL) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    const url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports?count=' + rowsPerPage + searchURL
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async createDownloadLink (id) {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    const url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports/' + id + '/link'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
