<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async loadPresets () {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    const url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports/presets?count=999'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async reportTypes () {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    const url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports/types'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async fileExt () {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    const url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports/extensions'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async reportStatus () {
    const token = await authService.getTokenSilently()
    const userPortal = await authService.user['https://customclaim.com/portal']
    const url = process.env.VUE_APP_APIURL + 'analytics/' + userPortal + '/reports/statuses'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
